.pl {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl__texts {
  width: 65%;
}

.pl__title {
  font-size: 50px;
  font-weight: 600;
}

.pl__desc {
  margin: 20px 0;
}

.pl__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .pl {
    padding: 10px;
  }

  .pl__title {
    font-size: 35px;
  }

  .pl__texts {
    width: 100%;
  }
}
