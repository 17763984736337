.c {
  height: 100vh;
  position: relative;
}

.c__bg {
  width: 20px;
  height: 100%;
  background-color: #5d3fd3;
  position: absolute;
}

.c__wrapper {
  padding: 50px;
  display: flex;
}

.c__left {
  flex: 1;
}

.c__right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c__title {
  font-size: 60px;
  width: 80%;
}

.c__info__item {
  display: flex;
  align-items: center;
  margin: 50px 0;
  font-weight: 300;
  width: 70%;
}

.c__icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.c__desc {
  font-weight: 200;
}

form {
  margin-top: 20px;
}

input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #5d3fd3;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 10px;
}

button {
  border: none;
  padding: 15px;
  background-color: #5d3fd3;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  width: 150px;
}

.submitted {
  font-size: 16px;
  color: #59b256;
  margin-left: 20px;
}

@media screen and (max-width: 480px) {
  .c__wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }

  .c__title {
    font-size: 30px;
  }

  .c__info__item {
    margin: 20px 0;
    width: 100%;
  }

  .c__desc {
    display: none;
  }

  form {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }
  button {
    margin-top: 10px;
  }
}
