.p {
  width: 30%;
  height: 40vh;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.p__browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p__circle {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
  margin: 3px;
}

.p__img {
  width: 100%;
  transition: all 7s ease;
}

.p:hover .p__img {
  transform: translateY(-50%);
}

@media screen and (max-width: 480px) {
  .p {
    width: 40%;
    height: 20vh;
  }
}
