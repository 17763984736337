body {
  overflow-x: hidden;
}

.i {
  display: flex;
  height: 100vh;
  visibility: hidden;
}

.i__left {
  flex: 1;
  display: flex;
  align-items: center;
}

.i__right {
  flex: 1;
  position: relative;
}

.i__left__wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.i__intro {
  font-size: 30px;
  font-weight: 300;
}

.i__name {
  font-size: 60px;
}

.i__title {
  height: 50px;
  overflow: hidden;
}

.i__title__wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i__title__item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #5d3fd3;
  display: flex;
  align-items: center;
}

.i__scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.i__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

.i__bg {
  clip-path: polygon(100% 0%, 100% 49%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  /* clip-path: circle(60.1% at 67% 50%); */
  /* background-color: #59b256; */
  background: url(../../img/galaxy.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 480px) {
  .i {
    flex-direction: column;
  }

  .i__left__wrapper {
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .i__desc {
    display: none;
  }

  .i__title__item {
    display: flex;
    justify-content: center;
  }
}
