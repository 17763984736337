.a {
  height: 100vh;
  display: flex;
  align-items: center;
}

.a__left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a__right {
  flex: 1;
}

.a__award__link {
  color: #5d3fd3;
}

.a__card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a__card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.a__award__img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  object-fit: cover;
  border: 2px solid #fff;
}

.a__title {
  font-weight: 400;
}

.a__sub {
  margin: 20px 0;
}

.a__desc {
  font-weight: 300;
}

.a__award {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a__award__texts {
  width: 70%;
}

.a__award__title {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .a {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }

  .a__left {
    width: 100%;
  }

  .a__card {
    height: 30vh;
  }

  .a__card.bg,
  .a__award {
    display: none;
  }

  .a__right {
    padding: 20px;
  }
}
